import {
  GET_JOB_DOCUMENT,
  GET_JOB_DOCUMENT_STAFF_LIST,
  GET_JOB_DOCUMENT_ORG_CERTIFICATES,
  SET_JOB_DOC_INITIALVALUES,
  CLEAR_JOB_DOC_INITIALVALUES,
  SET_ALLOCATED_DATA,
  UPDATE_USERS_TABLE,
  RESET_JOB_DOC,
  SET_JOB_DOC_ORGANISATIONS
} from "../../dataProvider/constant";

const INITIAL_STATE = {
  jobDetails: {},
  jobDocumentCreatorDetails: {},
  isJobSwms: 0,
  jobDocumentDetails: {},
  staffList: [],
  certificates: [],
  additionalDocs: [],
  swmsDocument: null,
  selectedAdditionalDocs: [],
  jobSwmsDoc: null,
  licenseTypes: [],
  chemicals: [],
  initialValues: {},
  allocatedTasks: [],
  allocatedChemicals: [],
  allocatedUsers: [],
  allocatedInsurances: [],
  organisations: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_JOB_DOCUMENT: {
      const { payload } = action;
      return {
        ...state,
        jobDetails: payload.job_details[0],
        jobDocumentDetails: payload.job_doc_details[0]
          ? payload.job_doc_details[0]
          : {},
        jobDocumentCreatorDetails: payload.job_doc_creator_details[0],
        licenseTypes: payload.licience_types,
        chemicals: payload.chemicals ? payload.chemicals : [],
        selectedAdditionalDocs: payload.job_doc_additional_docs,
        jobSwmsDoc: payload.job_swms_docs && payload.job_swms_docs.length >= 1 ? payload.job_swms_docs[0] : null,
        isJobSwms: payload.is_job_swms ? 1 : 0
      };
    }
    case GET_JOB_DOCUMENT_STAFF_LIST: {
      return {
        ...state,
        staffList: action.payload,
      };
    }
    case GET_JOB_DOCUMENT_ORG_CERTIFICATES: {
      return {
        ...state,
        certificates: action.payload.certificateList,
        additionalDocs: action.payload.job_doc_additional_docs,
        swmsDocument: action.payload.job_swms_docs
      };
    }
    case SET_JOB_DOC_INITIALVALUES: {
      return {
        ...state,
        initialValues: {
          ...state.initialValues,
          ...action.payload,
        },
      };
    }
    case CLEAR_JOB_DOC_INITIALVALUES: {
      return {
        ...INITIAL_STATE,
      };
    }
    case SET_ALLOCATED_DATA: {
      const { tasks, chemicals, users, insurances, initialValues } = action;
      return {
        ...state,
        allocatedTasks: tasks,
        allocatedChemicals: chemicals,
        allocatedUsers: users,
        allocatedInsurances: insurances,
        initialValues: Object.keys(initialValues).length
          ? { ...state.initialValues, ...initialValues }
          : INITIAL_STATE.initialValues,
      };
    }
    case UPDATE_USERS_TABLE: {
      return {
        ...state,
        allocatedUsers: action.payload,
      };
    }
    case RESET_JOB_DOC: {
      return INITIAL_STATE;
    }
    case SET_JOB_DOC_ORGANISATIONS: {
      return {
        ...state,
        organisations: action.payload
      }
    }
    default: {
      return state;
    }
  }
};
