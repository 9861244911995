import React, { useMemo, useState } from "react";
import JobStyles from "../../Job/JobStyle.module.css";
import { Checkbox, Collapse, Icon, Modal } from "antd";
import CloseIcon from '@mui/icons-material/Close';
import VideoPlayer from "../../../common/new-core-ui/VideoPlayer";
import JobReportRecord from "./JobReportRecord";
import styles from "../../v2Styles.module.css";
import AddEditReportRecord from "./AddEditReportRecord";
import ChangeRecordOrder from "./ChangeReportOrder";
import DeleteReportRecord from "./DeleteReportRecord";
import ReportFilters from "../ReportFilters";
import moment from "moment";

const { Panel } = Collapse;

export default function JobReportRecordsList({ reports, tasks, basePath, jobId, refreshReports, checkedRecords, onChange, onTaskChange, onAllSelect }) {

  const [videoFile, setVideoFile] = useState(null);
  const [videoModal, setVideoModal] = useState(false);
  const [addEditModal, setAddEditModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [changeOrderModal, setChangeOrderModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [filterData, setFilterData] = useState(null);

  const grouped = useMemo(() => {
    let usedTasks = {};
    let allReports = [...reports];
    if (filterData && filterData.range && filterData.range.length === 2) {
      allReports = allReports.filter(x => x.job_shift && moment(x.job_shift.shift_date).isBetween(filterData.range[0], filterData.range[1], "day", "[]"))
    }
    if (filterData && filterData.task_id) {
      allReports = allReports.filter(x => x.quote_task_id.toString() === filterData.task_id.toString())
    }
    if (tasks && reports) {
      allReports.forEach(report => {
        if (report.quote_task_id) {
          let taskName = tasks.find(x => x.id === report.quote_task_id)
          if (taskName) {
            usedTasks[report.quote_task_id] = {
              reports: [
                ...(usedTasks[report.quote_task_id] ? usedTasks[report.quote_task_id].reports : []),
                report
              ],
              task: taskName,
              reportIds: usedTasks[report.quote_task_id] ? [...usedTasks[report.quote_task_id].reportIds, report.id.toString()] : [report.id.toString()],
            }
          }
        }
      });
    }
    return usedTasks;
  }, [reports, tasks, filterData]);

  const closePreview = () => {
    setVideoModal(false);
    setVideoFile(null);
  }

  const onVideoPlay = (v) => {
    setVideoFile(v);
    setVideoModal(true);
  }

  const onDelete = (record) => {
    setSelectedRecord({
      id: record.id,
      job_id: jobId
    });
    setDeleteModal(true);
  }

  const onEdit = (rec) => {
    let task = tasks.find(x => x.id === rec.quote_task_id);
    setSelectedRecord({
      ...rec,
      files: rec.files && rec.files.filter(x => x.type === "Photo").map(y => ({
        file_name: {
          ...y,
          isUploaded: true,
          isFailed: false,
          file_base_path: basePath
        }
      })),
      video: rec.files && rec.files.filter(x => x.type === "Video").length ? {
        ...rec.files.filter(x => x.type === "Video")[0],
        isUploaded: true,
        isFailed: false,
        file_base_path: basePath
      } : null,
      quote_task_id: task ? {
        label: `${task.task_name}${task.area_name ? " - " + task.area_name : ""}`,
        value: task.id,
      } : null
    });
    setAddEditModal(true);
  }

  const addNewRecord = () => {
    setSelectedRecord({});
    setAddEditModal(true);
  }

  const onOrderUpdate = (rec) => {
    setSelectedRecord(rec);
    setChangeOrderModal(true);
  }

  const onFilter = (v) => {
    setFilterData(v);
  }
  return (
    <>
      <div className="row">
        <div className="col-12 pr-0">
          {Object.keys(grouped).length ? (
            <div className={`${JobStyles.BackgroundSectionHeading} custom-section-v2-panel-heading font-weight-normal my-2`} style={{ borderRadius: "8px" }}>
              <Checkbox
                onChange={(e) => { onAllSelect(e.target.checked) }}
                className="cs-select-v2 mr-2"
                checked={reports.length === checkedRecords.length}
                onClick={(e) => { e.stopPropagation(); }} >Select All Records</Checkbox>
            </div>) : null}
          <ReportFilters tasks={tasks} onFilter={onFilter} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 pr-0">
          <Collapse bordered={false} defaultActiveKey={"panel_0"} accordion className="custom-section-v2-collapse" expandIconPosition="right" expandIcon={({ isActive }) => (
            <Icon
              style={{ color: "#2f1f41" }}
              type="caret-right"
              rotate={isActive ? 90 : 0}
            />
          )}>
            {Object.keys(grouped).length ? Object.keys(grouped).map((key, index) => (
              grouped[key] ? (
                <Panel
                  style={{ border: '0px !important', marginTop: index === 0 ? "0px" : "10px" }}
                  header={
                    <div className={`${JobStyles.BackgroundSectionHeading} custom-section-v2-panel-heading font-weight-normal`}>
                      <Checkbox
                        checked={grouped[key].reportIds.every(x => checkedRecords.indexOf(x) !== -1)}
                        onChange={(e) => onTaskChange(grouped[key].reports, e)}
                        className="cs-select-v2 mr-2"
                        onClick={(e) => { e.stopPropagation() }} />{grouped[key].task ? (<>Task - {grouped[key].task.task_name} - {grouped[key].task.area_name}</>) : null}</div>
                  }
                  key={`panel_${index}`}
                  className={`custom-swms-category-header ${JobStyles.card}`}>
                  {grouped[key].reports.sort((a, b) => a.report_order && b.report_order ? a.report_order - b.report_order : 0).map(report => (
                    <JobReportRecord
                      onOrderUpdate={onOrderUpdate}
                      onEdit={onEdit}
                      report={report}
                      key={report.id}
                      checked={checkedRecords.indexOf(report.id.toString()) !== -1}
                      onChange={() => onChange(report)}
                      onDelete={onDelete}
                      refreshReports={refreshReports}
                      basePath={basePath}
                      onVideoPlay={onVideoPlay} />
                  ))}
                </Panel>) : null
            )) : null}
          </Collapse>
          <button
            className={`${JobStyles.PrimaryButton} ${JobStyles.SmallPrimaryButton} mt-2`}
            type="button"
            onClick={() => addNewRecord()}
          >Add New Record</button>
        </div>
      </div>
      {videoModal && videoFile &&
        <VideoPlayer file={videoFile} onCancel={closePreview} />
      }
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={addEditModal}
        onCancel={() => setAddEditModal(false)}
        afterClose={() => { setSelectedRecord(null); refreshReports(); }}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        width={"60vw"}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        <AddEditReportRecord
          record={selectedRecord}
          initialValues={selectedRecord ? selectedRecord : {}}
          onClose={() => setAddEditModal(false)}
          mode={selectedRecord && Object.keys(selectedRecord).length ? "Edit" : "Add"}
          jobId={jobId}
          tasks={tasks}
        />
      </Modal>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={changeOrderModal}
        onCancel={() => setChangeOrderModal(false)}
        afterClose={() => { setSelectedRecord(null); refreshReports(); }}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        <ChangeRecordOrder
          selectedRecord={selectedRecord}
          jobId={jobId}
          onClose={() => { setChangeOrderModal(false) }}
        />
      </Modal>

      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={deleteModal}
        onCancel={() => setDeleteModal(false)}
        afterClose={() => { setSelectedRecord(null); refreshReports(); }}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        <DeleteReportRecord
          selectedRecord={selectedRecord}
          onClose={() => setDeleteModal(false)}
        />
      </Modal>
    </>
  )
}