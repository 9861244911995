import React from "react";

import { useCallback, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import JobStyles from "../../Job/JobStyle.module.css";
import { IconButton } from "@mui/material";

export default function JobReportDraggablePhotos({ files, basePath, report, updateFiles, updateOrder, disabled }) {

  const movePhotos = useCallback((dragIndex, hoverIndex) => {
    if (dragIndex !== hoverIndex) {
      updateFiles(photos => {
        let allPhotos = [...photos];
        let [el] = allPhotos.splice(dragIndex, 1);
        allPhotos.splice(hoverIndex, 0, el);
        return allPhotos;
      });
      
    }
  });

  const renderCard = useCallback((card, cardIndex, disabled) => {
    return (
      <PhotoCard updateOrder={updateOrder} report={report} basePath={basePath} key={card.file_name} file={card} index={cardIndex} moveCard={movePhotos} disabled={disabled} />
    )
  })
  return (
    <DndProvider backend={HTML5Backend}>
      {files.map((card, i) => renderCard(card, i, disabled))}
    </DndProvider>
  )
}

const PhotoCard = ({ file, moveCard, basePath, report, index, updateOrder, disabled }) => {
  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: `job_report_${report.id}`,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    drop(_item, monitor) {
      updateOrder();
    },
    canDrop: () => !disabled,
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveCard(dragIndex, hoverIndex)
      // updateOrder()
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: `job_report_${report.id}`,
    item: () => {
      return { file, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => !disabled,
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <div ref={ref} className={`${JobStyles.ReportImageContainer} position-relative`} key={file.file_name} style={{ opacity, cursor: "unset" }} >
      <div className="position-absolute">
        <IconButton disabled={disabled} style={{ height: "auto", cursor: "grab" }} className="p-0" data-handler-id={handlerId}>
          <svg xmlns="http://www.w3.org/2000/svg" className={JobStyles.DragIcon} style={{ height: "14px", cursor: "grab" }} viewBox="0 0 320 512">
            <path fill="currentColor" d="M40 352l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zm192 0l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 320c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 192l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 160c-22.1 0-40-17.9-40-40L0 72C0 49.9 17.9 32 40 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40z" />
          </svg>
        </IconButton>
      </div>
      <img className={`${JobStyles.ReportImage} mx-auto`} src={basePath + file.file_name} alt="" />
    </div>
  )
}