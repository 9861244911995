import React, { useEffect, useState } from "react";
import { IconButton } from "rsuite";
import { CircularProgress } from '@mui/material';
import { useDispatch } from "react-redux";
import { notification } from "antd";
import CallIcon from '@mui/icons-material/Call';
import LanguageIcon from '@mui/icons-material/Language';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { getPreviewPoData } from "../../../../../actions/v2/JobAllocatonActions";
import { Strings } from "../../../../../dataProvider/localize";

import styles from "../../JobStyle.module.css";
import "./PreviewPOStyle.css";

export default function PreviewPORedesign({ onClose, task, jobLabel = "" }) {

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [poData, setPoData] = useState({});
  const [currentTask, setCurrentTask] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const fetchPreviewDetails = () => {
    let params = {
      job_id: task.job_id,
      site_id: task.site_id,
      contractor_id: task.contractor_id,
      task_id: currentTask.task_id,
      quote_task_id: currentTask.quote_task_id,
      task_label: currentTask.task_label,
      task_name: currentTask.task_name
    }
    setLoading(true);
    dispatch(getPreviewPoData(params)).then(data => {
      setPoData(data.po_batch_details[0]);
      setLoading(false);
    }).catch(err => {
      if (err) {
        setLoading(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: "ant-error"
        })
        onClose();
      }
    })
  }

  useEffect(() => {
    if (currentTask) {
      fetchPreviewDetails();
    }
  }, [currentTask])

  useEffect(() => {
    if (task && task.taskDetails && task.taskDetails.length > 0) {
      setCurrentTask(task.taskDetails[0]);
    } else {
      onClose();
    }
  }, [task]);

  const onCurrentChange = (val) => {
    setCurrentTask(task.taskDetails[val]);
    setCurrentIndex(val);
  }

  return (
    <div className={styles.PreviewContainer}>
      {loading ? (
        <div className="text-center">
          <CircularProgress />
        </div>
      ) : ""}
      {!loading && task.taskDetails && task.taskDetails.length > 1 && currentTask ? (
        <div className="container-fluid job-swms-modal-container pb-2">
          <div className="row py-2 border-bottom align-items-center">
            <div className="col-8">
              <div className={styles.QuoteEmailHead}>
                Task: {currentTask.task_label} - {currentTask.task_name}  ({currentIndex + 1}/{task.taskDetails.length})</div>
            </div>
            <div className="col-4">
              <div className="d-flex items-center justify-content-end">
                <IconButton disabled={currentIndex === 0} className={styles.ModalNextIcon} onClick={() => onCurrentChange(currentIndex - 1)}>
                  <ChevronLeftIcon sx={{ fontSize: "20px" }} />
                </IconButton>
                <IconButton className={`${styles.ModalNextIcon} ml-2`} disabled={currentIndex === task.taskDetails.length - 1} onClick={() => onCurrentChange(currentIndex + 1)}>
                  <ChevronRightIcon sx={{ fontSize: "20px" }} />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {!loading && poData && Object.keys(poData).length > 0 ? (
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-3">
              <div className={styles.PreviewLogoWrapper}>
                <img className={styles.JobPreviewLogo} src={poData.org_details && poData.org_details.org_logo ? poData.org_details.org_logo : ""}
                  alt={poData.org_details && poData.org_details.name ? poData.org_details.name : ""}></img>
              </div>
            </div>
            <div className="col-3">
              <div>
                <span className={styles.QuotePreviewContent}>{poData.org_details && poData.org_details.phone_number ?
                  (<span className="quote-icons-alignment"><CallIcon className="quote-preview-icons" /> {poData.org_details.phone_number}</span>) : ""}</span>
              </div>
              <div>
                <span className={styles.QuotePreviewContent}>{poData.org_details && poData.org_details.website ?
                  (<span className="quote-icons-alignment"><LanguageIcon className="quote-preview-icons" /> {poData.org_details.website}</span>) : ""}</span>
              </div>
              <div>
                <span className={styles.QuotePreviewContent}>{poData.org_details && poData.org_details.email_address ?
                  (<span className="quote-icons-alignment"><EmailIcon className="quote-preview-icons" /> {poData.org_details.email_address}</span>) : ""}</span>
              </div>
            </div>
            <div className="col-3">
              <div>
                <span className={styles.QuotePreviewContent}>{poData.org_details && poData.org_details.address ?
                  (<span className="quote-icons-alignment"><LocationOnIcon className="quote-preview-icons" /> {poData.org_details.address}</span>) : ""}</span>
              </div>
              <div>
                <span className={styles.QuotePreviewContent}>{poData.org_details && poData.org_details.abn_acn ?
                  (<span><span style={{ color: "#2aa3dd" }} className="font-weight-bold">ABN: </span>{poData.org_details.abn_acn}</span>) : ""}</span>
              </div>
            </div>
            <div className="col-3">
              <div className="my-0 pdf-head">PURCHASE ORDER</div>
            </div>
          </div>
          <div>
            <div className="row pt-3">
              <div className="col-12 py-2">
                <div className={styles.PreviewHeaderTxt}>Purchase Order Information</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="container-fluid px-0">
                  <div className="row quote-info-bottom">
                    <div className="col-3 quote-info-right">
                      <div className="bolded-text">From</div>
                    </div>
                    <div className="col-3 quote-info-right">
                      <div className="bolded-text">To</div>
                    </div>
                    <div className="col-3 quote-info-right">
                      <div className="bolded-text">Site</div>
                    </div>
                    <div className="col-3">
                      <div className="bolded-text text-right">Purchase Order Details</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3 quote-info-right">
                      <div>
                        <span>{poData.org_details.address}</span>
                      </div>
                      <div>
                        <span>{poData.org_details.email_address}</span>
                      </div>
                      <div>
                        <span>{poData.org_details.phone_number}</span>
                      </div>
                      <div>
                        <span>{poData.org_details.website}</span>
                      </div>
                      <div>
                        <span>{poData.org_details.abn_acn}</span>
                      </div>
                    </div>
                    <div className="col-3 quote-info-right">
                      <div>
                        <span>{poData.contractor_details.name}</span>
                      </div>
                      <div>
                        <span>{poData.contractor_details.address}</span>
                      </div>
                      <div>
                        <span>{poData.contractor_details.email_address}</span>
                      </div>
                      <div>
                        <span>{poData.contractor_details.phone}</span>
                      </div>
                    </div>
                    <div className="col-3 quote-info-right">
                      <div>
                        <span className="preview-header">Site Name: </span>
                        <span className="preview-header-placeholder">{poData.site_name}</span>
                      </div>
                      <div>
                        <span className="preview-header">Site Address: </span>
                        <span className="preview-header-placeholder">{poData.site_address}</span>
                      </div>
                    </div>
                    <div className="col-3 text-right">
                      <div>
                        <div>
                          <span className="preview-header">Purchase Order Number: </span>
                          <span className="preview-header-placeholder">{poData.PO_number}</span>
                        </div>
                      </div>
                      <div className="col-12 px-0">
                        <div>
                          <span className="preview-header">Date: </span>
                          <span className="preview-header-placeholder">{poData.PO_date}</span>
                        </div>
                        <div>
                          <span className="preview-header">Job Id: </span>
                          <span className="preview-header-placeholder">{jobLabel}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-12 py-2">
                <div className={styles.PreviewHeaderTxt}>Tasks</div>
              </div>
            </div>
            <div className="row mt-1">
              <div className="col-12">
                <div className="quote-preview-table-v2">
                  <table className="doc-quote-table-v2">
                    <tbody>
                      <tr className="quotes-table-header-v2">
                        <th width="8%">Id</th>
                        <th width="45%">Description</th>
                        <th width="10%">Quantity</th>
                        <th width="10%">Unit Price</th>
                        <th width="10%" className="text-right">Amount</th>
                      </tr>
                      {poData.task_details.map((task_detail, i) => (
                        <React.Fragment key={i}>
                          <tr>
                            <td>{task_detail.task_number}</td>
                            <td>{task_detail.task_name}</td>
                            <td>{task_detail.frequency_count}</td>
                            <td>{task_detail.contractor_task_cost}</td>
                            <td className="text-right">{task_detail.task_total_amount}</td>
                          </tr>

                          <tr className="mb-3" style={{ borderBottom: "2px solid #000" }}>
                            <td colSpan={3} className="text-right bolded-text">
                              Subtotal
                            </td>
                            <td colSpan={2} className={`text-right bolded-text`}>{task_detail.task_total_amount ? `${task_detail.task_total_amount}` : "0.00"}</td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-12">
                <div className="table-responsive job-preview-table">
                  <table className="table table-sm cs-table my-0">
                    <tbody>
                      <tr>
                        <td width="75%"></td>
                        <td width="10%">Subtotal</td>
                        <td width="15%" className={`text-right`}>
                          {poData.purchase_order_details ? `${poData.purchase_order_details.sub_total_amount}` : "0.00"}
                        </td>
                      </tr>
                      <tr>
                        <td width="60%"></td>
                        <td width="10%">GST</td>
                        <td width="15%" className={`text-right`}>
                          {poData.purchase_order_details ? `${poData.purchase_order_details.gst_cost}` : "0.00"}
                        </td>
                      </tr>
                      <tr>
                        <td width="60%"></td>
                        <td width="10%" className={`${styles.PreviewTableHeadContent} total-preview-back`}>Total</td>
                        <td width="15%" className={`${styles.PreviewTableHeadContent} text-right total-preview-back`}>
                          {poData.purchase_order_details ? `${poData.purchase_order_details.total_amount}` : "0.00"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row py-2">
              <div className="col-12">
                <div className={styles.PreviewHeaderTxt}>Purchase Order Terms and Conditions</div>
                <div className="special-notes-content"><div style={{ margin: "0px" }} dangerouslySetInnerHTML={{ __html: poData.org_details.po_tnc}} /></div>
              </div>
            </div>
            <div className="row py-2">
              <div className="col-12">
                <div className={styles.PreviewHeaderTxt}>Delivery Details</div>
                <div className="container-fluid">
                  <div className="row special-notes-content">
                    <div className="col-6">
                      <div className={styles.JobPreviewContent}>Delivery Address</div>
                      <div className={styles.JobPreviewContent}>{poData.org_details.delivery_address ? poData.org_details.delivery_address : ""}</div>
                    </div>
                    <div className="col-6 quote-task-conditions">
                      <div className={styles.JobPreviewContent}>Delivery Instructions</div>
                      <div dangerouslySetInnerHTML={{
                        __html: poData.org_details.delivery_instructions,
                      }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="eod-divider"></div>
            <div className="row">
              <div className="col-12 eod-sec text-center py-2">
                <div className={styles.PreviewHeaderTxt}>End of Order</div>
              </div>
            </div>
            <div className="eod-divider"></div>
          </div>
        </div>
      ) : ""
      }
    </div >
  )
}