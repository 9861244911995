import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import { Field, reduxForm } from "redux-form";
import { CustomSingleSelect } from "../../../common/new-core-ui/CustomSingleSelect";
import { getPayCode, getServiceType, getEmploymentType, getPayClassification, updateRosteringDetails, getOrgUserDetails } from "../../../../actions/profileManagementActions";
import moment from "moment";
import { getStorage } from "../../../../utils/common";
import { ADMIN_DETAILS } from "../../../../dataProvider/constant";
import { Strings } from "../../../../dataProvider/localize";
import validate from "./RosteringDetailsValidate";
import "./RosteringDetailsFormStyle.css";
import colourStyles from "../FilterSelectProfileStyles";
import { components } from "react-select";
import "../ProfileStyle.css";
import styles from "../ProfileStyles.module.css";

const RosteringDetailsForm = (props) => {

  const dispatch = useDispatch();

  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [payCodes, setPayCodes] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [payClassifications, setPayClassifications] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    setLoadingData(true);
    Promise.all([
      dispatch(getPayCode()),
      dispatch(getEmploymentType()),
      dispatch(getServiceType()),
      dispatch(getPayClassification()),
    ]).then(res => {
      setPayCodes(res[0].length > 0 ? res[0].map(payCode => ({ label: payCode.pay_code, value: payCode.id })) : [])
      setEmploymentTypes(res[1].length > 0 ? res[1].map(eType => ({ label: eType.employment_type, value: eType.id })) : [])
      setServiceTypes(res[2].length > 0 ? res[2].map(serviceType => ({ label: serviceType.service_type_name, value: serviceType.id })) : []);
      setPayClassifications(res[3] && res[3].pay_classifications && res[3].pay_classifications.length > 0 ? res[3].pay_classifications.map(payClassification => ({ label: payClassification.classification_name, value: payClassification.id })) : []);
      setLoadingData(false);
    }).catch(err => {
      setLoadingData(false);
      notification.error({
        message: Strings.error_title,
        description: err ? err : Strings.generic_error,
        className: "ant-error"
      })
    })

  }, []);

  const onSubmit = (values) => {
    let formData = {};
    let fetchPayCcodes = false;
    if (values.serviceType) {
      formData.service_type_id = values.serviceType.value;
    }
    if (values.payClassification) {
      formData.pay_classification_id = values.payClassification.value;
    }
    if (values.employmentType) {
      formData.employment_type_id = values.employmentType.value;
    }
    if (values.payCode) {
      fetchPayCcodes = true;
      if (values.payCode['__isNew__']) {
        formData.pay_code_name = values.payCode.value;
      } else {
        formData.pay_code = values.payCode.value;
      }
    }
    if (values.id) {
      formData.id = values.id;
    }
    if (props.savingAvail) {
      formData.staff_availability = JSON.stringify(props.savingAvail);
    }

    let adminDetails = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)) : null;

    let userName = values.user_name ? values.user_name : props.userDetails && props.userDetails.user_name ? props.userDetails.user_name : null;
    let orgId = adminDetails.organisation && adminDetails.organisation.id ? adminDetails.organisation.id : null;
    if (userName && orgId) {
      dispatch(updateRosteringDetails(formData, userName)).then((message) => {
        props.onSaveClose({ values: null, formData: {} });
        props.reset();
        notification.success({
          message: Strings.success_title,
          description: message ? message : Strings.success_title,
          className: 'ant-success'
        });
        if (fetchPayCcodes) {
          dispatch(getPayCode()).then(res => {
            setPayCodes(res.length > 0 ? res.map(payCode => ({ label: payCode.pay_code, value: payCode.id })) : [])
          });
        }
        dispatch(getOrgUserDetails(orgId, userName)).catch(err => {
          notification.error({
            message: Strings.error_title,
            description: err ? err : Strings.generic_error,
            className: 'ant-error'
          })
        });
      }).catch(err => {
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: 'ant-error'
        })
      })
    } else {
      notification.error({
        message: "Error",
        description: "There was an error while saving.",
        className: 'ant-error'
      })
    }
  }

  return (
    <form onSubmit={props.handleSubmit(onSubmit)}>
      <hr className={styles.DetailsDivider}></hr>
      <div className="container-fluid py-2 pt-3">
        <div className="row align-items-center">
          <h2 className={`${styles.ProfileLabel} font-weight-bold`}>Rostering</h2>
        </div>
        <div className="row">
          <div className="col-3 border-right">
            <div>
              <div className={styles.ProfileLabelV2}>Service Types</div>
              <div className="pb-3">
                <Field
                  name="serviceType"
                  component={CustomSingleSelect}
                  clearable={true}
                  styles={colourStyles}
                  otherProps={{
                    isLoading: loadingData,
                    components: { dropdownIndicator: <components.DownChevron /> }
                  }}
                  placeholder=""
                  options={serviceTypes}
                />
              </div>
            </div>

            <div className="pb-3">
              <div className={styles.ProfileLabelV2}>Pay Classification</div>
              <div>
                <Field
                  name="payClassification"
                  component={CustomSingleSelect}
                  clearable={true}
                  styles={colourStyles}
                  otherProps={{
                    isLoading: loadingData,
                    components: { dropdownIndicator: <components.DownChevron /> }
                  }}
                  placeholder=""
                  options={payClassifications}
                />
              </div>
            </div>
            <div className="pb-3">
              <div className={styles.ProfileLabelV2}>Employment Type</div>
              <div>
                <Field
                  name="employmentType"
                  component={CustomSingleSelect}
                  clearable={true}
                  styles={colourStyles}
                  otherProps={{
                    isLoading: loadingData,
                    components: { dropdownIndicator: <components.DownChevron /> }
                  }}
                  placeholder=""
                  options={employmentTypes}
                />
              </div>
            </div>
            <div className="pb-3">
              <div className={styles.ProfileLabelV2}>Pay Code</div>
              <div>
                <Field
                  name="payCode"
                  component={CustomSingleSelect}
                  placeholder=""
                  options={payCodes}
                  otherProps={{
                    isMulti: false,
                    styles: colourStyles,
                    components: {
                      isLoading: loadingData,
                      dropdownIndicator: <components.DownChevron />,
                      IndicatorSeparator: () => null,
                    },
                    menuPortalTarget: document.body
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-9 pt-3">
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center" style={{ columnGap: "5px" }}>
                <div className={`${styles.ProfileLabel} font-weight-bold`}>Availability</div>
                {props.savingAvail ? (<div style={{ color: "red", fontSize: "12px" }}>Please click <b>Save</b> to add your Availability.</div>) : ""}
              </div>
              <div className="ml-auto pb-2">
                <button type="button" className={styles.BtnSaveTab} onClick={(e) => { e.preventDefault(); props.addEditAvail(props.savingAvail ? 0 : 1) }}>
                  {props.savingAvail ? "Edit" : "Add"}
                </button>
              </div>
            </div>
            <div>
              <div className="table-responsive">
                <table className="table table-hover table-sm cs-table-cont cs-table-cont-v2">
                  <thead>
                    <tr>
                      <th>Valid From</th>
                      <th>Valid To</th>
                      <th>Mon</th>
                      <th>Tues</th>
                      <th>Wed</th>
                      <th>Thur</th>
                      <th>Fri</th>
                      <th>Sat</th>
                      <th>Sun</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.availabilities.map(record => (
                      record.staff_availability ? (
                        <tr key={record.id}>
                          <td>{record.staff_availability.valid_from ? moment(record.staff_availability.valid_from).format("DD-MM-YYYY") : "-"}</td>
                          <td>{record.staff_availability.valid_to ? moment(record.staff_availability.valid_to).format("DD-MM-YYYY") : "Current"}</td>
                          <td>{record.staff_availability.mon ? `${record.staff_availability.mon.start_time} - ${record.staff_availability.mon.end_time}` : ""}</td>
                          <td>{record.staff_availability.tue ? `${record.staff_availability.tue.start_time} - ${record.staff_availability.tue.end_time}` : ""}</td>
                          <td>{record.staff_availability.wed ? `${record.staff_availability.wed.start_time} - ${record.staff_availability.wed.end_time}` : ""}</td>
                          <td>{record.staff_availability.thur ? `${record.staff_availability.thur.start_time} - ${record.staff_availability.thur.end_time}` : ""}</td>
                          <td>{record.staff_availability.fri ? `${record.staff_availability.fri.start_time} - ${record.staff_availability.fri.end_time}` : ""}</td>
                          <td>{record.staff_availability.sat ? `${record.staff_availability.sat.start_time} - ${record.staff_availability.sat.end_time}` : ""}</td>
                          <td>{record.staff_availability.sun ? `${record.staff_availability.sun.start_time} - ${record.staff_availability.sun.end_time}` : ""}</td>
                          <td>{record.staff_availability.total_hours ? `${record.staff_availability.total_hours} hrs` : ""}</td>
                        </tr>
                      ) : null
                    ))}
                    {props.savingAvail ? (
                      <tr className={styles.AddedAvailRow}>
                        <td>{moment(props.savingAvail.week_start_date).format("DD-MM-YYYY")}</td>
                        <td>Current*</td>
                        <td>{props.savingAvail.mon ? `${props.savingAvail.mon.start_time} - ${props.savingAvail.mon.end_time}` : ''}</td>
                        <td>{props.savingAvail.tue ? `${props.savingAvail.tue.start_time} - ${props.savingAvail.tue.end_time}` : ''}</td>
                        <td>{props.savingAvail.wed ? `${props.savingAvail.wed.start_time} - ${props.savingAvail.wed.end_time}` : ''}</td>
                        <td>{props.savingAvail.thur ? `${props.savingAvail.thur.start_time} - ${props.savingAvail.thur.end_time}` : ''}</td>
                        <td>{props.savingAvail.fri ? `${props.savingAvail.fri.start_time} - ${props.savingAvail.fri.end_time}` : ''}</td>
                        <td>{props.savingAvail.sat ? `${props.savingAvail.sat.start_time} - ${props.savingAvail.sat.end_time}` : ''}</td>
                        <td>{props.savingAvail.sun ? `${props.savingAvail.sun.start_time} - ${props.savingAvail.sun.end_time}` : ''}</td>
                        <td>{props.savingAvail.total_hours ? `${props.savingAvail.total_hours} hrs` : ""}</td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="text-right pb-2 pt-3">
            <button type="submit"
              disabled={props.pristine && props.savingAvail === null}
              className={`${styles.BtnSaveTab} ${props.pristine && props.savingAvail === null ? styles.DisabledBtn : ''}`}>
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default reduxForm({
  enableReinitialize: true,
  form: "rostering_details_form",
  validate: validate
})(RosteringDetailsForm);
