import React, { useState, useEffect, useRef } from "react";
import { LinearProgress, Tooltip } from "@mui/material";
import { getJobHistory } from "../../../actions/SAJobMgmtAction";
import { useDispatch } from "react-redux";
import { Strings } from "../../../dataProvider/localize";
import { notification, Menu, Dropdown } from "antd";
import jobStyles from "../Job/JobStyle.module.css";
import { IconButton } from "@mui/material";
import PreviewDocument from "./PreviewDocument";
import { downloadDocument, getStorage } from "../../../utils/common";
import { ADMIN_DETAILS } from "../../../dataProvider/constant";
import { Modal } from "antd";
import { Close } from "@mui/icons-material";
import moment from "moment";

import v2Styles from "../v2Styles.module.css";

export default function CleaningReportHistory({ jobId, onEmailReport, updateKey }) {

  const dispatch = useDispatch();
  const controller = useRef();

  const [loading, setLoading] = useState(false);
  const [histories, setHistories] = useState([]);
  const [previewFile, setPreviewFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [resendModal, setResendModal] = useState(false);

  const fetchJobHistory = () => {
    setLoading(true);
    controller.current = new AbortController();
    dispatch(getJobHistory({ job_id: jobId }, { signal: controller.current.signal })).then(res => {
      setHistories([...res.jobReports, ...(res.jobSignOffs ? res.jobSignOffs.map(x => ({ ...x, isSignOff: true })) : [])]);
      setLoading(false);
    }).catch((err) => {
      if (err) {
        setLoading(false);
        notification.error({
          message: Strings.error_title,
          description: "There was an error while fetching Job History Details.",
          className: "ant-error"
        })
      }
    })
  }

  useEffect(() => {
    fetchJobHistory();
  }, [updateKey])

  useEffect(() => {
    fetchJobHistory();
    return () => {
      if (controller.current) {
        controller.current.abort()
      }
    }
  }, []);

  const openFilePreview = (file) => {
    setPreviewFile(file);
    setShowModal(true);
  }

  const closePreview = () => {
    setShowModal(false);
    setPreviewFile(null)
  }

  // const downloadAttachment = (link) => {
    // try {
      // let organisation = JSON.parse(getStorage(ADMIN_DETAILS)).organisation;
      // let domain = new URL(organisation.logo_path).origin;
      // let slug = organisation.slug;
      // let fullPath = `${domain}/${slug}/${link}`;
      // downloadDocument(link);
    // } catch (e) {
    //   notification.error({
    //     message: "Failed to Preview File.",
    //     className: "ant-error"
    //   });
    //   console.log(e);
    // }
  // }

  const handleOption = (v, report) => {
    switch (v) {
      case "1": {
        onEmailReport({
          report: { ...report.history },
          historyId: report.history.id,
          isResent: report.status === "Sent" ? true : false,
          saveHistory: 0,
          isOld: true,
        })
      }
        break;
      case "2": {
        onEmailReport({
          report: { ...report.history },
          historyId: report.history.id,
          isResent: false,
          saveHistory: 1,
          isOld: false
        })
      }
    }
  }

  const EmailButton = (history) => {
    const optionMenu = () => (
      <Menu onClick={(v) => handleOption(v.key, history)}>
        <Menu.Item key="1" disabled={loading} style={{ fontSize: "13px" }}>
          View Only Purpose
        </Menu.Item>
        <Menu.Item key="2" disabled={loading} style={{ fontSize: "13px" }}>
          For Sign Off Purpose
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown placement="bottomLeft" trigger={["click"]} overlayClassName="cs-v3-dropdown" overlay={optionMenu} disabled={loading}>
        <Tooltip placement="top" title="Resend Report">
          <span>
            <IconButton style={{ color: "#362148" }} disabled={loading} className={jobStyles.MuiIconButton}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: "14px", width: "14px" }}>
                <path d="M307 34.8c-11.5 5.1-19 16.6-19 29.2l0 64-112 0C78.8 128 0 206.8 0 304C0 417.3 81.5 467.9 100.2 478.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8 431.9 96 414.4 96 384c0-53 43-96 96-96l96 0 0 64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4z" />
              </svg>
            </IconButton>
          </span>
        </Tooltip>
      </Dropdown>
    )
  }

  return (
    <div key={updateKey}>
      <div className="row">
        <div className="col-12">
          <div style={{ height: "4px" }}>
            {loading ? (
              <LinearProgress />
            ) : ""}
          </div>
          <div>
            <table className="table-sm cs-table cs-table-v2" style={{ tableLayout: "fixed", width: "100%" }}>
              <thead>
                <tr>
                  <th width="15%">Report Date</th>
                  <th>Generated By</th>
                  <th width="15%">Status</th>
                  <th width="15%">Role</th>
                  <th width="120px">Actions</th>
                </tr>
              </thead>
              <tbody>
                {histories && histories.length > 0 ? histories.map(history => (
                  <tr key={`${history.isSignOff ? "sign_off" : "history"}_${history.id}`}>
                    <td>{history.created_at ? moment(history.created_at).format("DD/MM/YYYY hh:mm A") : history.sign_off_date_time ? moment(history.sign_off_date_time).format("DD/MM/YYYY hh:mm A") : "-"}</td>
                    <td>{history.user_full_name ? history.user_full_name : ""}</td>
                    <td style={{ textTransform: "capitalize" }}>{history.status}</td>
                    <td>{history.user_role ? history.user_role : ""}</td>
                    <td>
                      <div className="d-flex align-items-center" style={{ gap: "10px" }}>
                        <Tooltip placement="top" title="View Report">
                          <span>
                            <IconButton style={{ color: "#9abe3a" }} onClick={() => openFilePreview(history.isSignOff ? history.sign_off_pdf_link : history.path)} className={jobStyles.MuiIconButton}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style={{ height: "14px", width: "14px" }}>
                                <path fill="currentColor" d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                              </svg>
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip placement="top" title="Download Report">
                          <span>
                            <IconButton style={{ color: "#362148" }}
                              onClick={() => downloadDocument(history.isSignOff ? history.sign_off_pdf_link : history.path)}
                              className={jobStyles.MuiIconButton}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: "14px", width: "14px" }}>
                                <path fill="currentColor" d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                              </svg>
                            </IconButton>
                          </span>
                        </Tooltip>
                        {!history.isSignOff ? (
                          <EmailButton history={history} />
                        ) : null}
                      </div>
                    </td>
                  </tr>
                )) : (
                  <tr>
                    <td colSpan={5} className="text-center" style={{ color: "red" }}>No History Available.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {showModal && previewFile &&
        <PreviewDocument file={previewFile} onCancel={closePreview} />
      }
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={resendModal}
        onCancel={() => setResendModal(false)}
        // afterClose={() => { setSelectedField(null); fetchSwmsCategory(page, filterValues) }}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        closeIcon={<div className={v2Styles.ModalCloseIcon}><Close /></div>}
      >
        <div>
          <div className="ohs-modal-container">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className={v2Styles.ModalHeading}>Resend Report</div>
                  <div className="py-2">
                    <hr />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">

                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}